const RainbowFooter = () => {
  return (
    <footer>
      <div className="w-full flex h-[20px] bg-blue-500">
        <div className="w-4/12 bg-blue-700 h-full skew-x-[-30deg]"></div>
        <div className="w-2/12 bg-pink-600 h-full skew-x-[-30deg]"></div>
        <div className="w-1/12 bg-blue-500 h-full skew-x-[-30deg]"></div>
        <div className="w-2/12 bg-yellow-400 h-full skew-x-[-30deg]"></div>
        <div className="w-2/12 bg-pink-400 h-full skew-x-[-30deg]"></div>
      </div>
    </footer>
  );
};

export default RainbowFooter;
