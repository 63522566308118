import { observer } from "mobx-react-lite";
import fastomatImg from "../assets/fastomat.png";
import FastomatList from "../components/FastomatList";
import { useRef } from "react";
import { scrollToElement } from "../utils/scrollTo";

const Catalog = () => {
  const catalogScroll = useRef(null);
  const executeScroll = () => {
    scrollToElement(catalogScroll);
  };
  return (
    <div>
      <section className="bg-gradient-to-tr from-[#e5dbf9] to-purple-300 dark:from-purple-300 dark:to-[#4004bb]">
        <div className="container mx-auto flex flex-col-reverse md:flex-row py-20">
          <div className="w-full px-4 md:px-0 md:w-1/3 flex flex-col mx-auto md:mx-0 justify-center">
            <h1 className="text-3xl font-semibold dark:text-white">
              С фастаматом всегда <br />
              на высоте!
            </h1>
            <div>
              <p className="mt-8 text-gray-500 dark:text-gray-200">
                Бесконтактная выдача заказов - это удобный и безопасный способ
                получить товар, который позволяет сократить риск заражения,
                сохранить конфиденциальность и в тоже время получить желаемый
                товар в удобное для себя время.
              </p>
            </div>
          </div>
          <div className="w-full md:w-2/3 flex items-center justify-center pl-0 md:pl-28">
            <img className="w-full" src={fastomatImg} alt="" />
          </div>
        </div>
      </section>

      <section>
        <div className="flex flex-col-reverse md:flex-row">
          <div className="flex justify-center w-full md:w-1/2 py-14 md:py-10 dark:bg-slate-900 dark:text-white">
            <div className="w-full mx-6 md:px-0 md:w-1/2">
              <p className="font-bold my-10 text-2xl">О фастоматах</p>
              <p>
                Бесконтактная выдача заказов через почтомат - это удобный и
                безопасный способ доставки товаров. Почтоматы расположены в
                разных точках города, что делает их доступными для большинства
                покупателей.
              </p>
            </div>
          </div>
          <div className="flex justify-center w-full md:w-1/2 text-white bg-purple-500 dark:bg-[#4004bb] py-14 md:py-10  dark:text-white">
            <div className="w-full mx-6 md:px-0 md:w-1/2">
              <p className="font-bold my-10 text-2xl">Легкость использования</p>
              <p>
                Система работы с почтоматами проста и понятна: после оформления
                заказа на сайте интернет-магазина, покупатель выбирает удобный
                почтомат для получения заказа и указывает его адрес.
              </p>
              <div
                className="bg-purple-400 dark:bg-[#5607f9]  text-sm w-fit py-2 px-4 mt-6 cursor-pointer"
                onClick={executeScroll}
              >
                <p>К катологу</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto" id="catalog">
          <div className="pt-16">
            <p
              ref={catalogScroll}
              className="text-3xl font-bold text-black dark:text-white ml-6"
            >
              Каталог
            </p>
          </div>
        </div>
        <div className="flex container mx-auto mt-16 flex-wrap justify-center">
          <FastomatList />
        </div>
      </section>
      <div className="h-[10vh]"></div>
    </div>
  );
};
export default observer(Catalog);
