import Header from "./Header";
import PreHeader from "./PreHeader";
import RainbowFooter from "./RainbowFooter";

const MainLayout = ({ children }) => {
  return (
    <div className="dark:bg-slate-900">
      <PreHeader />
      <Header />
      {children}/
      <RainbowFooter />
    </div>
  );
};

export default MainLayout;
