import "./App.css";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/HomePage";
import Catalog from "./pages/Catalog";
import AboutUs from "./pages/AboutUs";
import * as system from "reakit-system-bootstrap";
import MainLayout from "./components/Layout";
import { Provider } from "reakit";

function App() {
  return (
    <Provider unstable_system={system}>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
      </MainLayout>
    </Provider>
  );
}

export default observer(App);
