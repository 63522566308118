import { observer } from "mobx-react-lite";
import fastomats from "../store/fastomats";

const FastomatItem = ({ id, name, price, image, basketCount }) => {
  const addToBasket = () => {
    fastomats.List.find((e) => {
      if (e.id === id) {
        e.basketCount++;
      }
    });
  };
  const removeFromBasket = () => {
    fastomats.List.find((e) => {
      if (e.id === id) {
        e.basketCount--;
      }
    });
  };

  return (
    <div className="dark:text-white border-2 min-h-64 w-64 p-5 rounded m-6 py-8  flex flex-col justify-between">
      <div className="flex justify-center items-center h-full">
        <img src={image} alt="" />
      </div>
      <div>
        <p className="font-bold text-center mt-4">{name}</p>
        <p className="font-bold text-2xl mt-4">{price} ₽/шт</p>
        {basketCount ? (
          <div className="mx-auto w-full  flex justify-between items-center px-7 mt-6 select-none">
            <div
              className="bg-red-500 flex text-center justify-center h-8 w-8 items-center rounded cursor-pointer"
              onClick={() => removeFromBasket()}
            >
              <p className="text-white font-bold cursor-pointer mb-[2px]">-</p>
            </div>
            <div>
              <p className="font-semibold text-center">{basketCount}</p>
            </div>
            <div
              className="bg-green-500 flex text-center justify-center h-8 w-8 items-center rounded cursor-pointer"
              onClick={() => addToBasket()}
            >
              <p className="text-white font-bold cursor-pointer mb-[2px]">+</p>
            </div>
          </div>
        ) : (
          <div className="mx-auto w-full  bg-purple-500 mt-4 rounded">
            <p
              className="text-white font-bold text-center py-2 cursor-pointer"
              onClick={() => addToBasket()}
            >
              В корзину
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(FastomatItem);
