import VKImg from "../assets/vk.png";
import TelegramImg from "../assets/telegram.png";
import WhatsAppImg from "../assets/whatsapp.png";
import RutubeImg from "../assets/rutube.webp";
import YanexDzenImg from "../assets/yanexdzenlogo.svg";

export const socials = [
  {
    name: "VK",
    title: "",
    link: "https://vk.com/takeyours.online",
    img: VKImg,
  },
  {
    name: "Telegram",
    title: "",
    link: "https://t.me/TAKEYOURS_ONLINE",
    img: TelegramImg,
  },
  {
    name: "What's App",
    title: "",
    link: "https://wa.me/79205778788",
    img: WhatsAppImg,
  },
  {
    name: "Rutube",
    title: "",
    link: "https://rutube.ru/u/ty/",
    img: RutubeImg,
  },
  {
    name: "Yandex Dzen",
    title: "",
    link: "https://shorturl.at/efiot",
    img: YanexDzenImg,
  },
];
