import { socials } from "../config-store/socials";

const SocialList = () => {
  return (
    <>
      {socials.map((social) => (
        <a href={social.link}>
          <img
            className="rounded-full w-[25px] h-[25px]"
            src={social.img}
            width={30}
            alt={social.title}
          />
        </a>
      ))}
    </>
  );
};

export default SocialList;
