import LazyLoad from "react-lazyload";
import { Button } from "reakit/Button";
import SocialList from "./SocialList";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMailOpenOutline } from "react-icons/io5";

const HrefButtonWithIcon = ({ href, text, icon }) => {
  return (
    <a rel="nofollow" href={href}>
      <Button className="flex items-center gap-2">
        {icon}
        <span>{text}</span>
      </Button>
    </a>
  );
};

const PreHeader = () => {
  return (
    <div className="py-2 hidden border-b grid-cols-2 md:grid lg:grid-cols-3 justify-between gap-3 items-center text-xs font-light">
      <div className="hidden lg:flex w-full justify-center items-center"></div>
      <LazyLoad>
        <div className="flex w-full border-x justify-center gap-8 items-center">
          <SocialList />
        </div>
      </LazyLoad>

      <div className="flex w-full gap-3 justify-center items-center">
        <HrefButtonWithIcon
          href={"mailto:hello@take-yours.ru"}
          icon={<IoMailOpenOutline />}
          text={"hello@take-yours.ru"}
        />
        <HrefButtonWithIcon
          href={"tel:+7 (499) 380-78-99"}
          icon={<FaPhoneAlt />}
          text={"+7 (499) 380-78-99"}
        />
      </div>
    </div>
  );
};

export default PreHeader;
